import React from "react"
import Helmet from "react-helmet"
import palette from "../design/palette"
import Styles from "../design/styles"
import SEO from "../components/SEO"
import PageFooter from "../components/PageFooter"

type DefaultLayoutProps = {
  children: JSX.Element[] | JSX.Element
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({ children }) => (
  <div id="default-layout">
    <SEO />

    <Helmet>
      <html lang="no" />
      <meta name="theme-color" content={palette.accent} />
      <link rel="icon" type="image/png" href="/img/bonum-icon.png" />
    </Helmet>

    <Styles />

    {children}

    <PageFooter />
  </div>
)

export default DefaultLayout
