import React from "react"
import Image from "../components/Image"

const PageFooter = () => (
  <footer className="text-gray-600 bg-black relative">
    <section
      className="w-full px-6 pt-12 pb-16 border-t-2 border-b-2 border-gray-900 border-solid"
      style={{ fontSize: 17 }}
    >
      <div className="mx-auto w-full max-w-3xl flex justify-between flex-wrap">
        <nav
          role="navigation"
          className="mt-6 sm:w-1/2 xs:w-full xs:text-center xs:mb-3"
        >
          <Image
            src="/img/bonum-logo.png"
            alt="Bonum logo"
            className="mb-3 xs:mx-auto"
            style={{
              maxHeight: 30
            }}
          />

          <a id="footer-bonum-email-link" className="mb-1 block" target="_blank" rel="noopener" href="mailto:kontakt@bonum.no">kontakt@bonum.no</a>
          <a id="footer-bonum-phone-link" className="mb-1 block" target="_blank" rel="noopener" href="tel://004722232385">+47 222–32–385</a>
        </nav>

        <nav
          role="navigation"
          className="mt-6 sm:w-1/2 xs:w-full xs:text-center xs:mb-3"
        >
          <h3 className="mb-6 text-lg text-white tracking-widest uppercase">
            Bonum
          </h3>
          <a id="footer-bonum-selskapet-link" className="mb-1 block" target="_blank" rel="noopener" href="https://bonum.no">Om Selskapet</a>
          <a id="footer-bonum-utvikling-link" className="mb-1 block" target="_blank" rel="noopener" href="https://bonum.no/utvikling">Bonum Utvikling</a>
          <a id="footer-bonum-ansatte-link" className="mb-1 block" target="_blank" rel="noopener" href="https://bonum.no/ansatte">Våre ansatte</a>
          <a id="footer-bonum-stillinger-link" className="mb-1 block" target="_blank" rel="noopener" href="https://bonum.no/karriere">Se stillinger</a>
          <a id="footer-bonum-media-link" className="" target="_blank" rel="noopener" href="https://bonum.no/media">Media</a>
        </nav>

        <nav
          role="navigation"
          className="mt-6 sm:w-1/2 xs:w-full xs:text-center xs:mb-3"
        >
          <h3 className="mb-6 text-lg text-white tracking-widest uppercase">
            Utforsk
          </h3>
          <a id="footer-bonum-prosjekter-link" className="mb-1 block" target="_blank" rel="noopener" href="https://bonum.no/prosjekter">Alle Prosjekter</a>
          <a id="footer-bonum-holgerslystveien-link" target="_blank" rel="noopener" href="https://holgerslystveien.bonum.no">Holgerslystveien</a>
          <a id="footer-bonum-eugeniesgate-link" className="mb-1 block" target="_blank" rel="noopener" href="https://eugeniesgate.bonum.no">Eugenies gate</a>
          <a id="footer-bonum-vakeroveien-link" className="mb-1 block" target="_blank" rel="noopener" href="https://vakeroveien.bonum.no">Vækerøveien</a>
          <a id="footer-bonum-akersveien-link" className="mb-1 block" target="_blank" rel="noopener" href="https://akersveien.bonum.no">Akersveien</a>
        </nav>

        <nav
          role="navigation"
          className="mt-6 sm:w-1/2 xs:w-full xs:text-center"
        >
          <h3 className="mb-6 text-lg text-white tracking-widest uppercase">
            Websiden
          </h3>
          <a id="footer-bonum-personvern-link" className="mb-1 block" target="_blank" rel="noopener" href="https://bonum.no/personvern">Personvern</a>
          <a id="footer-bonum-cookies-link" className="" target="_blank" rel="noopener" href="https://bonum.no/cookies">Cookies</a>
        </nav>
      </div>
    </section>

    <section className="w-full px-6 py-8 text-center text-sm">
      <p>
        Bonum © 2019
      </p>
    </section>
  </footer>
)

export default PageFooter
