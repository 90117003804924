import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

interface SEOProps {
  title?: string
  description?: string
  image?: string
  pathName?: string
  article?: boolean
  publishedAt?: string
}

const SEO: React.FC<SEOProps> = ({
  title,
  description,
  image,
  pathName,
  article = false,
  publishedAt
}) => {
  const { site } = useStaticQuery(query)

  const {
    siteMetadata: {
      defaultTitle,
      titleTemplate,
      defaultDescription,
      siteUrl,
      defaultImage,
      twitterUsername
    }
  } = site

  const meta = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathName || "/"}`
  }

  const structuredData = [{
    "@context": "http://schema.org",
    "@type": "Organization",
    "name": "Bonum",
    "alternateName": [
      "Bonum AS",
      "Bonumbygg",
      "Bonum Eiendom",
      "Bonum Eiendomsutvikler",
      "Bonum Utvikling"
    ],
    "legalName": "Bonum AS",
    "url": "https://bonum.no",
    "foundingDate": "2011",
    "description": "Bonum bygger attraktive boliger tilpasset ulike typer mennesker. For Bonum sine prosjekter er det lagt fokus på helhetsbildet, og hovedvekt på funksjonelle moderne boliger.",
    "logo": "https://assets.bonum.no/img/logo/bonum-logo-small-black-lo.png",
    "image": meta.image,
    "sameAs": [
      "https://facebook.com/BonumNO",
      "https://twitter.com/BonumAS",
      "https://instagram.com/bonum.no",
      "https://linkedin.com/company/Bonum-AS"
    ],
    "numberOfEmployees": {
      "@type" : "QuantitativeValue",
      "name" : "51-200"
    },
    "parentOrganization": {
      "@type" : "Organization",
      "name" : "Nemento AS"
    },
    "telephone": "+47-222-32-385",
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Oslo",
      "addressRegion": "Oslo",
      "streetAddress": "Parkveien 37",
      "postalCode": "0258",
      "email": "kontakt@bonum.no",
      "telephone": "+47-222-32-385"
    },
    "contactPoint": [{
      "@type": "ContactPoint",
      "telephone": "+47-222-32-385",
      "contactType": "customer service"
    }]
  }]

  const breadcrumbs = [{
    "@type": "ListItem",
    position: 1,
    item: {
      "@id": siteUrl,
      name: "Forside"
    }
  }]

  structuredData.push({
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    description: "Breadcrumbs list",
    name: "Breadcrumbs",
    itemListElement: breadcrumbs
  })

  return (
    <>
      <Helmet
        title={meta.title}
        titleTemplate={titleTemplate}
      >
        {meta.title && <meta name="twitter:title" content={meta.title} />}
        {meta.title && <meta property="og:title" content={meta.title} />}

        {meta.description && <meta name="description" content={meta.description} />}
        {meta.description && <meta property="og:description" content={meta.description} />}
        {meta.description && <meta name="twitter:description" content={meta.description} />}

        {meta.image && <meta name="image" content={meta.image} />}
        {meta.image && <meta property="og:image" content={meta.image} />}
        {meta.image && <meta name="twitter:image" content={meta.image} />}

        {meta.url && <meta property="og:url" content={meta.url} />}

        {twitterUsername && <meta name="twitter:creator" content={twitterUsername} />}
        {twitterUsername && <meta name="twitter:card" content="summary_large_image" />}

        <meta property="og:type" content="website" />
        <meta property="fb:app_id" content="1253609801428791" />

        {structuredData.map((data, index) => (
          <script
            key={index}
            type="application/ld+json"
          >
            {JSON.stringify(data)}
          </script>
        ))}
      </Helmet>
    </>
  )
}

export default SEO

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl
        defaultImage: image
        twitterUsername
      }
    }
  }
`
